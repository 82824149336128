
import { defineComponent } from 'vue';
import Datatable from "@/components/kt-datatable/KTDatatableLeft.vue";
import Swal from 'sweetalert2/dist/sweetalert2.min.js';
import CourseInfoDrawer from '@/layout/header/partials/course/CourseInfoDrawer.vue';
import { VueCookieNext } from 'vue-cookie-next';

// import * as Yup from "yup";
import ApiService from '@/core/services/ApiService';

export default defineComponent({
  name: 'new-entity-pending-course',
  components: {
    Datatable,
    CourseInfoDrawer,
  },
  data() {
    return {
      data: {},
      actionApprove: false,
      actionReject: false,
      actionEdit: false,
      courseHeader: [
        {
          name: 'Actions',
          key: 'actions',
          sortable: false,
          width: '200px',
        },

        {
          name: 'Code.',
          key: 'code',
          sortable: true,
        },
        {
          name: 'Name',
          key: 'course_name',
          sortable: true,
          width: '200px',
        },
        {
          name: 'Tranche',
          key: 'tranche',
          sortable: true,
        },
        {
          name: 'Term',
          key: 'term',
          sortable: true,
        },
        {
          name: 'Session',
          key: 'session',
          sortable: true,
        },
        {
          name: 'Type',
          key: 'course_type',
          sortable: true,
        },
        {
          name: 'Mgt Course?',
          key: 'isManagementCourse',
          sortable: true,
          width: '200px',
        },
        {
          name: 'Stipend Course?',
          key: 'isStipendCourse',
          sortable: true,
          width: '200px',
        },
        {
          name: 'Tot Course?',
          key: 'isTotCourse',
          sortable: true,
          width: '200px',
        },
        {
          name: 'Employment Eligible?',
          key: 'isEmploymentEligible',
          sortable: true,
          width: '200px',
        },
        {
          name: 'Month',
          key: 'month',
          sortable: true,
        },
        {
          name: 'Hour',
          key: 'hour',
          sortable: true,
        },
        {
          name: 'Total Training Days',
          key: 'ttd',
          sortable: true,
          width: '200px',
        },

        {
          name: 'Total Trainee',
          key: 'total_target_trainee',
          sortable: true,
        },
        {
          name: 'Unit Cost',
          key: 'unit_cost',
          sortable: true,
          width: '170px',
        },
         {
          name: 'Requested by',
          key: 'requested_by',
          sortable: true,
        },
        {
          name: 'Request Date Time',
          key: 'requested_date_time',
          sortable: true,
          width: '150px',
        },
      ],
      courseList: [],
      componentKey: 0,
      load: true,

      // tableData: [
      //   {
      //     id: 1,
      //     name: "Welding & Fabrication (NE) ",
      //     code: "W&F (NE)",
      //     type: "New Entrant",
      //     Mgt: "Yes ",
      //     month: "3",
      //     trance: "TR-1 ",
      //     unit_cost: "43413",
      //   },
      //   {
      //     id: 2,
      //     name: "Electrical & Navigation Equipment Installation (NE) ",
      //     code: "E&N (NE) ",
      //     type: "New Entrant",
      //     Mgt: "Yes",
      //     month: "3",
      //     trance: "TR-1 ",
      //     unit_cost: "31674",
      //   },
      // ],

      lists: [],
    };
  },
  async created() {
    await this.getPendingCourseInfos();
    await this.actioncheck();
    Object.assign(this.courseList, this.lists);
    this.emitter.on('course-updated', async () => {
      await this.getPendingCourseInfos();
      this.componentKey += 1;
      this.courseList = this.lists;
    });
  },
  methods: {
    async getPendingCourseInfos() {
      let entity_id = '';
      if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
        entity_id = VueCookieNext.getCookie('_entity_id');
      }
      await ApiService.get('course/pending_list?entity_id=' + entity_id)
        .then((response) => {
          this.load = false;
          this.lists = response.data.data;
          console.log(response.data.data);
          this.componentKey += 1;
        })
        .catch(({ response }) => {
          this.load = false;
          console.log(response);
        });
    },

    edit(data) {
      this.emitter.emit('course-updated-drawer', data);
    },

    Approve(id) {
      let data = {
        active_status: 1,
      };
      Swal.fire({
        title: 'Are you sure you want to approve it?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Approved!',
      }).then((result) => {
        if (result.isConfirmed) {
          ApiService.update('course/approve-course/' + `${id}`, data)
            .then((response) => {
              this.emitter.emit('course-updated', true);
              this.componentKey += 1;
              Swal.fire('Approved!', response.data.data, 'success');
            })
            .catch(({ response }) => {
              console.log(response);
            });
        }
      });
    },
    async actioncheck() {
      let menu = JSON.parse(localStorage.getItem('menu') || '{}');

      if (menu) {
        for (let i = 0; i < menu.length; i++) {
          //console.log(menu[i].action);
          if (menu[i].heading == 'Course') {
            //console.log(menu[i].action);
            let actions = menu[i].action;
            //console.log(actions);
            for (let j = 0; j < actions.length; j++) {
              if (actions[j].action_name === 'Approve') {
                this.actionApprove = true;
              }
              if (actions[j].action_name === 'Edit') {
                this.actionEdit = true;
              }
              if (actions[j].action_name === 'Reject') {
                this.actionReject = true;
              }
            }
          }
        }
      }
    },
    Reject(id) {
      let data = {
        active_status: 1,
      };
      Swal.fire({
        title: 'Are you sure you want to reject it?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Rejected!',
      }).then((result) => {
        if (result.isConfirmed) {
          ApiService.update('course/status_change/' + `${id}`, data)
            .then((response) => {
              this.emitter.emit('course-updated', true);
              this.componentKey += 1;
              Swal.fire('Rejected!', response.data.data, 'success');
            })
            .catch(({ response }) => {
              console.log(response);
            });
        }
      });
    },
  },
  setup() {
    const AssociationSchema = [];
    return {
      AssociationSchema,
    };
  },
});
